<template style="padding-bottom: 50px;">
  <van-notice-bar
      left-icon="volume-o"
      text="用户朋友你们好，您现在处在麦苗TMS运输管理系统客户订单查询页面。请您对物流公司提供客户单号时单号中不要包含 / \ 等特殊符号。页面如有问题，请联系麦苗供应链。如有不便，还望海涵！"
  />
  <div style="width: 100%;height: 200px;">
    <van-swipe :autoplay="3000" lazy-render style="width: 100%;">
      <van-swipe-item v-for="image in images" :key="image">
        <img :src="image" style="width: 100%;height: 200px"/>
      </van-swipe-item>
    </van-swipe>
  </div>
  <div class="content">
    <div class="input_box">
      <input type="text" v-model="customerOrderNo" placeholder="请输入单号" clearable>
      <button size="small" type="primary" class="input_box_button" @click="onSearch">查询</button>
    </div>
  </div>
  <div v-if="isShow === 1">
    <div class="content">
      <strong>运单跟踪</strong>
      <van-steps direction="vertical" :active="data.nodeLists.length !== 0 ? 0:data.nodeLists.length-1" active-color="#38f">
        <van-step v-for="(item,index) in data.nodeLists" :key="index">
          <text class="node-text">{{ item.nodeText }}</text>
          <div class="node-time">{{ item.gmtCreate }}</div>
        </van-step>
      </van-steps>
    </div>
    <div class="content">
      <strong>运单信息</strong>
      <van-cell-group inset>
        <van-field label="客户单号" :model-value="data.detail.customerOrderNo" readonly />
        <van-field label="运输区间" :model-value="data.detail.fromCity+' → '+data.detail.toCity" readonly />
        <van-field label="始发地" :model-value="data.detail.fromArea+data.detail.fromAddress" readonly />
        <van-field label="目的地" :model-value="data.detail.toArea+data.detail.toAddress" readonly />
        <van-field label="件重体" :model-value="data.detail.totalBoxQty+'件；'+data.detail.totalWeight+'KG；'+data.detail.totalVolume+'方'" readonly />
        <van-field label="需求时间" :model-value="data.detail.planTime" readonly />
        <van-field label="计划到达时间" :model-value="data.detail.planArrivedTime" readonly />
        <van-field label="创建时间" :model-value="data.detail.gmtCreate" readonly />
      </van-cell-group>
    </div>
    <div class="content" v-if="data.file.length>0">
      <strong>运单照片</strong>
      <div style="text-align: center;" v-for="(item, index) in data.file" :key="index">
        <img :src="data.file[index]" class="order-file" @click.stop="viewPic"/>
      </div>
    </div>
  </div>
  <div v-if="isShow === 2">
    <van-empty image="search" description="未查询到订单信息" />
  </div>
  <div v-if="isShow === 3">
    <van-empty
        class="custom-image"
        image="https://img.yzcdn.cn/vant/custom-empty-image.png"
        description="请根据承运商提供的客户单号查询信息"
    />
  </div>
</template>

<script>
import { Divider, Image, Uploader,ImagePreview , Icon, Checkbox, CheckboxGroup, Card, SubmitBar, Steps, Step, Field, CellGroup, Button, Swipe, SwipeItem, Empty, Tabbar, TabbarItem, Tabs, Tab, NoticeBar, Toast, Notify } from 'vant';
import apiUri from "@/api/uri";
import httpApi from "@/api/httpApi";

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Field.name]: Field,
    [Empty.name]: Empty,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [NoticeBar.name]: NoticeBar,
    [CellGroup.name]: CellGroup,
    [ImagePreview.name]: ImagePreview,
    [Image.name]: Image,
    [Uploader.name]: Uploader,
    [Divider.name]: Divider,
    [Icon.name]: Icon
  },
  name: "cart",
  data() {
    return {
      isFixed: false,
      offsetTop:0,
      customerOrderNo: '',
      isShow: 3,
      active: 0,
      detailActive: 0,
      data: {
        detail: {},
        file: [],
        nodeLists: []
      }
    }
  },
  props: {
  },
  activated() {
  },
  created() {
    if (this.$route.query.cus) {
      this.customerOrderNo = this.$route.query.cus
      this.onSearch()
    }
  },
  mounted(){
  },
  computed: {
  },
  setup() {
    const images = [
      require('@/images/swagger2.webp')
    ];
    return { images };
  },
  methods: {
    onSearch() {
      if (!this.customerOrderNo || this.customerOrderNo === '') {
        Toast.fail('请输入客户单号');
        this.isShow = 2
        return false
      }
      Toast.loading({
        duration: 0,
        message: '查询中',
        forbidClick: true
      });
      httpApi.get(apiUri.fill(apiUri.customer_no,this.customerOrderNo)).then(R => {
        const { code, data } = R.data
        console.log(code)
        if (code === 0) {
          console.log(data)
          Toast.clear(true);
          Notify({
            message: '已更新',
            color: '#06a7e2',
            background: '#047397',
            duration: 1000
          });
          this.isShow = 1
          this.detailActive = 0
          this.orderDetail(data)
          this.orderFiles(data);
          this.nodeList(data)
        } else {
          Toast.clear(true);
          Notify({
            message: '查询不到该客户单号对应订单信息',
            color: '#ad0000',
            background: '#ffe1e1',
            duration: 1000
          });
          this.isShow = 2
        }
      }).catch(ER => {
        Toast.clear(true);
        Notify({
          message: '查询不到该客户单号对应订单信息',
          color: '#ad0000',
          background: '#ffe1e1',
          duration: 1000
        });
        this.isShow = 2
      })
    },
    viewPic() {
      ImagePreview({
        images: this.data.file,
        closeable: true,
      });
    },
    orderFiles(orderId) {
      this.data.file = []
      httpApi.get(apiUri.fill(apiUri.order_file, orderId, '4,8')).then(R => {
        const { code, data } = R.data
        if (code === 0) {
          console.log(data)
          if(data.length > 0) {
            this.data.file.push(data[0])
          }
          if(data.length > 1) {
            this.data.file.push(data[1])
          }
        }
      })
    },
    orderDetail(orderId) {
      httpApi.get(apiUri.fill(apiUri.order_id,orderId)).then(R => {
        const { code, data } = R.data
        if (code === 0) {
          this.data.detail = data
          console.log(this.data.detail)
        }
      })
    },
    nodeList(orderId) {
      httpApi.get(apiUri.fill(apiUri.node_list, orderId)).then(R => {
        let { code, data } = R.data
        if (code === 0) {
          this.data.nodeLists = data.reverse()
        }
      })
    }
  }
};
</script>

<style lang="less">
.custom-image {
  padding-top: 100px;
}
.custom-image .van-empty__image {
  width: 90px;
  height: 90px;
}
.content {
  background: #ffffff;
  width: 95%;
  padding: 2.5%;
  margin-top: 10px;

  .input_box {
    font-size: 16px;
    width: 100%;
    height: 30px;
    background: rgba(255,255,255,0.9);
    border: solid 1px #555555;
    position: relative;
    input {
      width: 95%;
      height: 95%;
      padding-left: 3px;
      border-style: none;
    }
    button {
      position: absolute;
      border-style: none;
      background-color: #555555;
      color: #ffffff;
      right: -1px;
      top: -1px;
      height: 32px;
      width: 30%;
      font-size: 14px;
    }
  }
  .van-step {
    .node-time {
      padding-top: 10px;
    }
  }
  .van-field__label {
    width: 30%;
    text-align: right;
  }
  strong {
    display: block;
    width: 70px;
    font-size: 14px;
    text-align: center;
    border-bottom: solid 3px #ff4800;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .order-file {
    width: 150px;
    height: 100px;
    margin-bottom: 20px;
  }
}
.van-cell-group--inset {
  border-style: none;
  margin: 0;
  .van-cell {
    padding: 0;
    line-height: 35px;
  }
}
</style>
